.web-dev-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: black;
  }
  .title-web {
    color: whitesmoke;
    font-size: 6rem;
  }
  .center-image {
    width: 300px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .animated-text {
    font-size: 2rem;
    color: black; 
    display: inline-block;
    position: relative;
  }
  
  @media only screen and (max-width: 600px) {
    .web-dev-container{
      margin-top: -2px;
    }
.title-web{
  font-size: 3rem;
}
  }
  @keyframes colorAnimation {
    0% { color: black; }
    10% { color: red; }
    20% { color: black; }
    30% { color: red; }
    40% { color: black; }
    50% { color: red; }
    60% { color: black; }
    70% { color: red; }
    80% { color: black; }
    90% { color: red; }
    100% { color: black; }
  }
  
  .animated-text span {
    animation: colorAnimation 1.5s infinite;
    animation-fill-mode: both;
  }
  
  .animated-text span:nth-child(1) { animation-delay: 0s; }
  .animated-text span:nth-child(2) { animation-delay: 0.1s; }
  .animated-text span:nth-child(3) { animation-delay: 0.2s; }
  .animated-text span:nth-child(4) { animation-delay: 0.3s; }
  .animated-text span:nth-child(5) { animation-delay: 0.4s; }
  .animated-text span:nth-child(6) { animation-delay: 0.5s; }
  .animated-text span:nth-child(7) { animation-delay: 0.6s; }
  .animated-text span:nth-child(8) { animation-delay: 0.7s; }
  .animated-text span:nth-child(9) { animation-delay: 0.8s; }
  .animated-text span:nth-child(10) { animation-delay: 0.9s; }
  .animated-text span:nth-child(11) { animation-delay: 1s; }
  .animated-text span:nth-child(12) { animation-delay: 1.1s; }
  .animated-text span:nth-child(13) { animation-delay: 1.2s; }
  .animated-text span:nth-child(14) { animation-delay: 1.3s; }
  .animated-text span:nth-child(15) { animation-delay: 1.4s; }
  .animated-text span:nth-child(16) { animation-delay: 1.5s; }
  .animated-text span:nth-child(17) { animation-delay: 1.6s; }
  .animated-text span:nth-child(18) { animation-delay: 1.7s; }
  .animated-text span:nth-child(19) { animation-delay: 1.8s; }
  .animated-text span:nth-child(20) { animation-delay: 1.9s; }
  .animated-text span:nth-child(21) { animation-delay: 2s; }
  .animated-text span:nth-child(22) { animation-delay: 2.1s; }
  .animated-text span:nth-child(23) { animation-delay: 2.2s; }
  .animated-text span:nth-child(24) { animation-delay: 2.3s; }
  .animated-text span:nth-child(25) { animation-delay: 2.4s; }
  .animated-text span:nth-child(26) { animation-delay: 2.5s; }
  .animated-text span:nth-child(27) { animation-delay: 2.6s; }
  .animated-text span:nth-child(28) { animation-delay: 2.7s; }
  .animated-text span:nth-child(29) { animation-delay: 2.8s; }
  .animated-text span:nth-child(30) { animation-delay: 2.9s; }
  .animated-text span:nth-child(31) { animation-delay: 3s; }
  .animated-text span:nth-child(32) { animation-delay: 3.1s; }
  .animated-text span:nth-child(33) { animation-delay: 3.2s; }
  