.footer {
    background-color: #000000;
    color: white;
    padding: 20px 0;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer p {
    margin: 0;
    padding: 10px 0;
  }
  
  