.not-found-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    color: white;
    text-align: center;
  }
  
  .background-video-not {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  
  .bottom-text-not {
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
    font-size: 24px;
  }
  