.red-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px; 
    height: 300px; 
    border: 2px solid black;
    border-radius: 10px;
    overflow: hidden;
    transition: border-color 0.3s ease;
  }
  
  .image-container {
    width: 100%;
    height: 110px; 
    width: 300px;
    overflow: hidden;
    transition: all .3s;
  }
  
  .image {
    width: 100%;
    height: auto;
    filter: grayscale(100%); 
    transition: filter 0.3s ease;
    border-radius: 5px;
  }
  
  .text-container {
    width: 300px;
    height: 100px; 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
     border: 2px solid white;
    
  }
  
  .text {
    color: white;
    font-size: 20px;
    padding: 10px;
   
    border-radius: 5px;
    transition: color 0.3s ease, border-color 0.3s ease;
  }
  .image-container:hover {
    width: 100%;
    height: 200px; 
    width: 300px;
    overflow: hidden;
  }
  .red-box:hover .image {
    filter: grayscale(0%);
  }
  
  .red-box:hover .text{
    color: red;
  }
  .red-box:hover .text-container {
    border-color: red; 
  }
  