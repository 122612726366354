
.services-container {
    height: 100vh; 
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: whitesmoke;
    width: 100wh;
  }
  
  .services-content {
    text-align: center;
  }
  
 
  .redboxes {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 100px; 
    gap: 200px;
  }
  @media only screen and (max-width: 600px) {
    .redboxes{
      flex-direction: column;
    }
    .services-container{
      height: 200vh;
    }

  }