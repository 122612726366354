
html, body {
    height: 100%;
    margin: 0;
  }

  .video-container {
    position: relative;
    width: 100%;
    height: 100vh; 
    overflow: hidden; 
  }
  
  
  .video-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%; 
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1; 
  }
  

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  
  .action-button {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bolder;
    background-color: rgba(245, 245, 245, 0.7); 
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: fit-content;
    justify-self: center
  }
  
  .action-button:hover {
    background-color: whitesmoke; /* Darker shade of blue on hover */
  }
  .logo{
    display: flex ;
    flex-direction: column;
    justify-content: space-around;
  }
  .logo img{
    margin-top: -200px;
    height: 600px;
  }
  .down{
    padding-top: 60px;
    height: 20px;
  }
  @media only screen and (max-width: 600px) {
   .logo{
    font-size: .5rem;
   }
   .logo img{
    width: 300px;
    height: auto;
    margin-top: -100px;
   }
  }