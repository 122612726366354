/* Photos.css */

.photos-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 50px;
    background-color: black;
    color: whitesmoke;
    
  }
  
  .image-photos {
    max-width: 100%;
    height: auto;
    border: 1px solid rgba(255, 255, 255, 0.432);
    border-radius: 50px;
    
  }

  .underphoto img{
    height: 300px;
    transition: all 10s;
  }
  .underphoto img:hover{
    filter: hue-rotate(360deg);
  }
  .vertical-image img{
    height: 800px;
    transition: all .3s;
  }
  .vertical-image img:hover{
    border: 1px solid white;
    border-radius: 0px;
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
  transform: translateX(25%);
  transition-timing-function: var(--bounce-out);
  filter: hue-rotate(120deg);
  filter: invert(0%);
  
  }
  
  .title-section {
    text-align: center;
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  
  .title {
    margin-top: -50px;
    margin-bottom: 20px;
    transition: all .3s;
  }
  .title:hover{
    color: red;
  }
  
  .image-text {
    font-size: 16px;
    margin-top: 10px;
  }
  
  .gif{
    height: 210px;
  }
  .horizontal-image{
    display: flex;
    margin-top: 100px;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
  }
  .underphoto{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 600px) {
    .photos-container{
      width: 100%;
      padding: 0;
      overflow-y: hidden;
      margin-top: -2px;
    }
    .vertical-image{
      display: none;
    }
    .gif{display: none;}
    .underphoto img{
      width: 250px;
      height: auto;
      border-radius: 10px;
    }
    .title-section{
      font-size: 1.3rem;
    }
    .title{
      margin-top: -10px;
    }
  }