.video-editing-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding: 20px;
    height: 100vh;
    background-color: black;
    color: white;
  }
  
  .youtube-section{
    width: 100%;
    max-width: 800px;
    text-align: center;
  }
  .award-image-section {
    max-width: 800px;
    text-align: center;
  }
  
  .yt-title {
    font-size: 6rem;
    margin-bottom: 20px;
  }
  .yt-title-shadow {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: red;
  }
  
  .youtube-iframe {
    width: 100%;
    height: 450px; 
    max-width: 800px;
    border: 1px solid white;
    border-radius: 20px;
    transition: all .5s;
  }
  .youtube-iframe:hover {
    border: 1.5px solid red;
  }
  .youtube-iframe:hover > .yt-title-shadow {
    opacity: 0%;
  }
  
  .award-image {
    width: 100%;
    height: auto;
    max-width: 300px;
  }
  
  .image-text {
    font-size: 16px;
    width: 400px;
    margin-top: 10px;
  }
  @media only screen and (max-width: 600px) {
   .video-editing-container{
    flex-direction: column;
    margin-top: -2px;
   }
    .yt-title{
    font-size: 2.5rem;
   }
   .award-image{
    width: 100px;
   }
   .youtube-iframe {
    width: 80%;
    height: auto; 
    max-width: 800px;
    border: 1px solid white;
    border-radius: 20px;
    transition: all .5s;
  }
    }