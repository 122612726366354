.contact-container {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: black;
    color: whitesmoke;
  }
  
  .right-image {
    top: 20px;
    right: 20px;
    width: 450px;
    height: auto;
  }
  
  .centered-text {
    justify-self: flex-start;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 36px;
    color: whitesmoke;

  }
  
  
  .bottom-section {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 20px;
  }
  @media only screen and (max-width: 600px) {
    .contact-container{
      margin-top: -2px;
      gap: 40px;
    }
    .right-image{
      width: 300px;
    }
    .bottom-section{
      flex-direction: column;
    }
  
  }