.contact-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    position: relative;
    cursor: pointer;
    border: 1px solid white;
  }
  
  .content-contact {
    display: flex;
    align-items: center;
    gap: 20px; 
  }
  
  .hover-text-contact {
    left: 0;
    background-color: black;
    border: 5px solid black;
    padding: 5px;
    white-space: nowrap;
    color: black;
    transition: all .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-decoration: none;
    border-radius: 5px;
  }
  .hover-text-contact a{
opacity: 0;
text-decoration: none;
  }
  
  .contact-box:hover .hover-text-contact {
    background-color: whitesmoke;
  }
  .contact-box:hover .hover-text-contact a{
    opacity: 100;
    text-decoration: none;
    color: black;
  }
  
  .image-container-contact {
    display: flex;
    align-items: center;
    opacity: 100;
  }
  
  .image-contact {
    width: 50px; 
    height: auto;
  }
  